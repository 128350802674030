<template>
  <div>
    <v-form ref="generateRegulations" @submit.prevent="generateRegulations">
      <v-container class="form__container" fluid>
        <v-row>
          <v-col>
            <calendesk-information-message>
              {{ $trans("generate_regulations_info") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
        <v-row v-if="!isPolish">
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyName"
              :label="$trans('full_company_name')"
              :placeholder="$trans('full_company_name_example')"
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyAddress"
              :label="$trans('full_company_address')"
              :placeholder="$trans('full_company_address_example')"
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyName"
              :label="$trans('full_company_name')"
              :hint="$trans('full_company_name_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyAddress"
              :label="$trans('full_company_address')"
              :hint="$trans('full_company_address_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsServiceName1"
              :label="$trans('regulations_data_services_1_label')"
              :hint="$trans('regulations_data_services_1_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsServiceName2"
              :label="$trans('regulations_data_services_2_label')"
              :hint="$trans('regulations_data_services_2_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyURL"
              :label="$trans('regulations_data_company_url_label')"
              :hint="$trans('url_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyEmail"
              :label="$trans('regulations_data_company_email_label')"
              :hint="$trans('regulations_data_company_email_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsCompanyPhone"
              :label="$trans('regulations_data_company_phone_label')"
              :hint="$trans('regulations_data_company_phone_example')"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.required, rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" md="6" class="d-flex align-center">
            <v-text-field
              v-model="regulationsDate"
              :label="$trans('regulations_data_date_label')"
              :hint="$trans('example_short') + ' ' + todayExample"
              persistent-hint
              outlined
              hide-details="auto"
              validate-on-blur
              :rules="[rules.maxChars(255)]"
              :disabled="isLoading"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex align-center justify-end">
            <v-btn
              color="blue"
              type="submit"
              outlined
              :disabled="isLoading"
              :loading="isGeneratingRegulations"
            >
              {{ $trans("generate_regulations") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-form ref="saveForm" @submit.prevent="save">
      <v-container class="form__container" fluid>
        <template v-if="!regulationsEmpty">
          <v-row>
            <v-col class="text-h6">
              {{ $trans("profile_company_regulations_header") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <tip-tap
                v-model="regulations"
                :placeholder="
                  $trans('profile_company_regulations_label_textarea')
                "
                :disabled="isLoading"
                @input="regulations = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center">
              <v-btn
                color="primary"
                depressed
                type="submit"
                :disabled="isLoading"
                :loading="isLoading"
              >
                {{ $trans("save") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-h6">
              {{ $trans("profile_company_privacy_policy_header") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <tip-tap
                v-model="privacyPolicy"
                :placeholder="
                  $trans('profile_company_privacy_policy_label_textarea')
                "
                :disabled="isLoading"
                @input="privacyPolicy = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center">
              <v-btn
                color="primary"
                depressed
                type="submit"
                :disabled="isLoading"
                :loading="isLoading"
              >
                {{ $trans("save") }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import planActions from "@/calendesk/mixins/planActions";
import {
  alphanumericSMSSender,
  email,
  maxChars,
  minChars,
  required,
  url,
} from "@/lib/calendesk-js-library/forms/validators";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import TipTap from "@/lib/calendesk-js-library/components/TipTap.vue";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "CompanyRegulations",
  components: { TipTap, CalendeskInformationMessage },
  mixins: [planActions],
  data() {
    return {
      regulations: null,
      privacyPolicy: null,
      isLoading: false,
      isGeneratingRegulations: false,
      regulationsCompanyName: null,
      regulationsCompanyAddress: null,
      regulationsServiceName1: null,
      regulationsServiceName2: null,
      regulationsCompanyURL: null,
      regulationsCompanyEmail: null,
      regulationsCompanyPhone: null,
      regulationsDate: null,
      todayExample: null,
      rules: {
        email,
        maxChars,
        minChars,
        url,
        required,
        alphanumericSMSSender,
      },
    };
  },
  computed: {
    ...mapGetters({
      configuration: "setup/getConfiguration",
    }),
    regulationsEmpty() {
      return !this.regulations && !this.privacyPolicy;
    },
    isPolish() {
      return this.$config("language") === this.$helpers.languages.POLISH;
    },
  },
  watch: {
    configuration() {
      this.copyCompanyNameAndAddress();
    },
  },
  created() {
    this.getRegulations();
    this.copyCompanyNameAndAddress();
  },
  methods: {
    ...mapActions({
      updateConfiguration: "setup/updateConfiguration",
    }),
    generateRegulations() {
      if (this.canGenerateRegulations) {
        if (!this.$refs.generateRegulations.validate()) {
          errorNotification("form_is_invalid", {}, false);
          return;
        }

        pushEvent("generateRegulations");

        this.isGeneratingRegulations = true;

        const data = {
          company_name: this.regulationsCompanyName,
          company_address: this.regulationsCompanyAddress,
        };

        if (this.isPolish) {
          data.services_name_1 = this.regulationsServiceName1;
          data.services_name_2 = this.regulationsServiceName2;
          data.company_url = this.regulationsCompanyURL;
          data.company_email = this.regulationsCompanyEmail;
          data.company_phone = this.regulationsCompanyPhone;
          data.creation_date = this.regulationsDate;
        }

        api
          .generateRegulations(data)
          .then((response) => {
            successNotification("generate_regulations_success");
            this.regulations = response.data.regulations;
            this.privacyPolicy = response.data.privacy_policy;
          })
          .catch((error) => {
            errorNotification("generate_regulations_fail", error);
          })
          .finally(() => {
            this.isGeneratingRegulations = false;
          });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    async getRegulations() {
      try {
        this.isLoading = true;
        this.setIsSending(true);
        const regulationsResponse = await api.configuration("regulations");

        if (regulationsResponse && regulationsResponse.data) {
          this.regulations = regulationsResponse.data.regulations;
        }

        const privacyPolicyResponse = await api.configuration("privacy_policy");
        if (privacyPolicyResponse && privacyPolicyResponse.data) {
          this.privacyPolicy = privacyPolicyResponse.data.privacy_policy;
        }
      } catch (err) {
        errorNotification("operation_failed", err);
      }

      this.setIsSending(false);
      this.isLoading = false;
    },
    save() {
      if (!this.$refs.saveForm.validate()) {
        errorNotification("form_is_invalid", {}, false);
        return;
      }

      if (this.privacyPolicy && this.privacyPolicy.length > 200000) {
        errorNotification("privacy_policy_description_too_long", {}, false);
        return;
      }

      if (this.regulations && this.regulations.length > 200000) {
        errorNotification("regulations_description_too_long", {}, false);
        return;
      }

      this.isLoading = true;
      pushEvent("updateRegulations");

      this.updateConfiguration({
        privacy_policy: this.privacyPolicy,
        regulations: this.regulations,
      })
        .then(() => {
          successNotification("update_success");
        })
        .catch((error) => {
          errorNotification("company_data_failed", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    copyCompanyNameAndAddress() {
      this.regulationsCompanyEmail = this.configuration.company_email;
      this.regulationsCompanyPhone = this.configuration.company_phone;
      this.regulationsCompanyName = this.configuration.company_name;
      this.regulationsDate = this.$moment().format(
        this.$helpers.dayAndMonthVerballyFull
      );

      this.todayExample = this.regulationsDate;

      this.regulationsCompanyAddress = "";

      if (this.configuration.company_street) {
        this.regulationsCompanyAddress = this.configuration.company_street;
      }

      if (this.configuration.company_city) {
        if (this.configuration.company_street) {
          this.regulationsCompanyAddress += ", ";
        }

        this.regulationsCompanyAddress += this.configuration.company_city;
      }

      if (this.configuration.company_postal_code) {
        this.regulationsCompanyAddress += ` ${this.configuration.company_postal_code}`;
      }
    },
  },
};
</script>
